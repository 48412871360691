<template>
  <div>
    <navbar-component language="ja"></navbar-component>
    <main>
      <div class="relative pt-16 pb-32 flex content-center items-center justify-center" style="min-height: 75vh;">
        <div class="absolute top-0 w-full h-full bg-center">
          <img src="../assets/img/background.png" class="absolute top-0 w-full h-full bg-center" />
          <span id="blackOverlay" class="w-full h-full absolute bg-black" style="opacity: 0.0"></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  良く。速く。安く。
                </h1>
                <p class="mt-4 text-2xl text-gray-200">
                  PEM 燃料電池用の新世代MEA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class=" fill-current" points="2560 0 2560 100 0 100" style=" color: #d0dcec"></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20  -mt-8" style="background-color: #0f59aa33 ">
        <div class="container mx-auto px-4">

          <div class="flex flex-wrap items-center ">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                style="color: #0F59AA">
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                次世代MEA
              </h3>
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                PEM 燃料電池は水素をエネルギーに変換します。膜電極組み立ては燃料電池の核心であり、これを可能にします。
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                Epagoの特許技術により、MEAとGDEを開発し、お客様のFCスタックのニーズにカスタマイズすることができます。
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg "
                style="background-color: #012463;">
                <img alt="..."
                src="../assets/img/fc.png"
                  class="w-full align-middle rounded-t-lg" />
                <blockquote class="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                    class="absolute left-0 w-full block" style="height: 95px; top: -94px;">
                    <polygon points="-30,95 583,95 583,65" class="fill-current" style="color: #012463;"></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    10年以上の専門知識
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Epagoのチームは、新エネルギー材料分野で10年以上の経験を持つ科学者と専門家で構成されています。
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img alt="..." class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1627052428109-576e839d100a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80" />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full"
                style="background-color: #0F59AA;">
                <i class="fas fa-rocket text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">水素移行を強化する</h3>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                Epagoの特許技術は非常に汎用性が高く、さまざまな産業において

使用されるPGM触媒を作成することができます。
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="fas fa-fingerprint"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">
                        長距離輸送用重責スタック
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="fab fa-html5"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">フォークリフトやオートバイ、スクーター用の中・軽責スタック</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="far fa-paper-plane"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">電解装置用MEA</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">チームに会おう</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                私たちのチームは、世界トップの大学出身の化学者、量子物理学者、データサイエンティストの国際的なミックスで構成されています。
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-1-800x800.jpeg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Andrea Grippi</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    CEO、共同創業者
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"
                      type="button" href="https://www.linkedin.com/in/andrea-grippi/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-2-800x800.jpeg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Haoyu Zhu</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    CTO、共同創業者
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"

                      type="button" href="https://www.linkedin.com/in/haoyu-zhu/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i

></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-4-470x470.png" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Prof. Guanhua Chen</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    ディレクター＆アドバイザー
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"
                      type="button" href="https://www.linkedin.com/in/chen-guanhua-569a4a6/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-10 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">私たちのパートナー</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                高いプロフィールの学術機関の支援を受けており、関連する民間企業との関係を構築しています
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/hku.jpg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">香港大学</h5>
                 
                 
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/preinvel.jpg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Preinvel SRL</h5>
                 
                 
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/hkqai.png" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">香港量子AIラボ</h5>
                
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"

                      type="button" href="https://www.linkedin.com/company/hkqai/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      
      <section class="pb-20 relative block " style="background-color: #012463">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="fill-current"  style="color: #012463" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">拡張可能な設計</h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-400">
                私たちのテクノロジーは設計上拡張可能です。
                急速な水素産業のニーズに適しています。
                専門知識とユニークな技術により、時間と経済の節約を提供し、高い性能を保証します。
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
                >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                適切なサイズ
              </h6>
              <p class="mt-2 mb-4 text-gray-400">
                正確な金属誘導技術により、耐久性と出力の高いMEAを作成できます
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-

12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
                >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                パフォーマンス
              </h5>
              <p class="mt-2 mb-4 text-gray-400">
                ユーザーの要求に応じて、高い性能の触媒層を提供します。
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
                >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                持続可能性
              </h5>
              <p class="mt-2 mb-4 text-gray-400">
                環境に優しい材料とプロセスにより、持続可能なエネルギー変換プロセスを実現します。
              </p>
            </div>
          </div>
        </div>
      </section>

    </main>
    <footer-component></footer-component>

  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
  name: "landing-page-jp",
  components: {
    NavbarComponent,
    FooterComponent
  }
}
</script>