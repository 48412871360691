<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 "
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
      <img alt="..."
                  src="../assets/img/EPAGO-LOGO-WHITE.png"
                  style="width: 250px" /><button
          class="cursor-pointer text-2xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none"
        v-bind:class="{'hidden': !showMenu, 'block': showMenu}"
      >
        
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li  class="flex items-center">
            <a
              class="sm:hidden lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              ><router-link
                class="lg:text-white font-bold text-gray-500 fab text-lg leading-lg "
                to="/en"
              >ENG</router-link
              ></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href
              ><router-link
                class="lg:text-white font-bold text-gray-500 fab text-lg leading-lg "
                to="/zh"
              >中文</router-link
              ></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href
              ><router-link
                class="lg:text-white font-bold text-gray-500 fab text-lg leading-lg "
                to="/jp"
              >日本語</router-link
              ></a
            >
          </li>
          
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
    }
  },
  props: ['language'],
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>
