<template>
  <div>
    <navbar-component language="en"></navbar-component>
    <main>
      <div class="relative pt-16 pb-32 flex content-center items-center justify-center" style="min-height: 75vh;">
        <div class="absolute top-0 w-full h-full bg-center">
          <img src="../assets/img/background.png" class="absolute top-0 w-full h-full bg-center" />
          <span id="blackOverlay" class="w-full h-full absolute bg-black" style="opacity: 0.0"></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  Better. Faster. Cheaper.
                </h1>
                <p class="mt-4 text-2xl text-gray-200">
                  New Generation MEA for PEM Fuel Cells
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class=" fill-current" points="2560 0 2560 100 0 100" style=" color: #d0dcec"></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20  -mt-8" style="background-color: #0f59aa33 ">
        <div class="container mx-auto px-4">

          <div class="flex flex-wrap items-center ">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                style="color: #0F59AA">
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Next Generation MEA
              </h3>
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                PEM Fuel Cells convert Hydrogen into Energy. The Membrane Electrode Assembly is the core of the Fuel Cell, making this possible.
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                Through its patented technology Epago develops MEAs and GDEs that can be customized for the need of your FC stack.
              </p>
              <!--<a
                href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                class="font-bold text-gray-800 mt-8"
                >Check Tailwind Starter Kit!</a
              >-->
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg "
                style="background-color: #012463;">
                <img alt="..."
                src="../assets/img/fc.png"
                  class="w-full align-middle rounded-t-lg" />
                <blockquote class="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                    class="absolute left-0 w-full block" style="height: 95px; top: -94px;">
                    <polygon points="-30,95 583,95 583,65" class="fill-current" style="color: #012463;"></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Over 10 years expertise
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Epago's team is composed by scientists and professionals with over 10 years of experience in new energy materials.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img alt="..." class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1627052428109-576e839d100a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80" />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full"
                style="background-color: #0F59AA;">
                <i class="fas fa-rocket text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">Empowering the Hydrogen transition</h3>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                Epago's patented technology is extremely versatile and is able to create PGM Catalyst suitable for a
                variety of applications across different industries
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="fas fa-fingerprint"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">
                        Fuel Cell stacks for heavy duty long-haul transportation
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="fab fa-html5"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Medium-Light duty stacks for forklifts or motorbikes and scooters</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="far fa-paper-plane"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">MEAs for Electrolyzers</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Meet the team</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                Our team is composed by an international mix of chemists, quantum phyisics experts and data scientists
                from top wordlwide univerities.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-1-800x800.jpeg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Andrea Grippi</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    CEO, Co-Founder
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"
                      type="button" href="https://www.linkedin.com/in/andrea-grippi/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-2-800x800.jpeg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Haoyu Zhu</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    CTO, Co-Founder
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"

                      type="button" href="https://www.linkedin.com/in/haoyu-zhu/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-4-470x470.png" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Prof. Guanhua Chen</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Director & Advisor
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"
                      type="button" href="https://www.linkedin.com/in/chen-guanhua-569a4a6/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-10 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Our partners</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                We are supported by high profile academic institutions and building relationships with relevant private enterprises 
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/hku.jpg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">The University of Hong Kong</h5>
                 
                 
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/preinvel.jpg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Preinvel SRL</h5>
                 
                 
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/hkqai.png" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Hong Kong Quantum AI LAB</h5>
                
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" style="background-color: #012463"

                      type="button" href="https://www.linkedin.com/company/hkqai/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      
      <section class="pb-20 relative block " style="background-color: #012463">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="fill-current"  style="color: #012463" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">Built to scale</h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-400">
                Our technology is scalable by design.
                It suits the needs of the fast pacing Hydrogen industry we are building.
                It delivers time and economic savings while also guaranteeing high performances thanks
                to our expertise and unique technique.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
                >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                The right size
              </h6>
              <p class="mt-2 mb-4 text-gray-400">
                Our accurate metal-inducing technique enables us to create MEAs with good durability and power output
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                The right type
              </h5>
              <p class="mt-2 mb-4 text-gray-400">
                Our technique is suitable to operate with most PG metals, enabling us to create MEAs for different applications
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">The right timing</h5>
              <p class="mt-2 mb-4 text-gray-400">
                Epago's technique allows for fast manufacturing of MEAs, enabling us to deliver in short timeframes even for custom projects
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative block py-24 lg:pt-0 "  style="background-color: #012463">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg" style="background-color: #d0dcec">
                <div class="flex-auto p-5 lg:p-10">
                  <form action="https://formcarry.com/s/rKdDiZ4434k" method="POST" accept-charset="UTF-8">
                    <h4 class="text-2xl font-semibold">Want to talk with us?</h4>
                    <p class="leading-relaxed mt-1 mb-4 text-gray-00">
                      Complete this form and we will get back to you as soon as possible.
                    </p>
                    <div class="relative w-full mb-3 mt-8">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="full-name">Full
                        Name</label><input type="text" name="fullName"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Full Name" style="transition: all 0.15s ease 0s;" />
                    </div>
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="email">Email</label><input type="email" name="email"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Email" style="transition: all 0.15s ease 0s;" />
                    </div>
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="message">Message</label><textarea rows="4" cols="80" name="message"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."></textarea>
                    </div>
                    <div class="text-center mt-6">
                      <button
                        class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="submit" style="transition: all 0.15s ease 0s;">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent
  }
}
</script>
