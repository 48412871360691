<template>
  <div>
    <navbar-component language="zh"></navbar-component>
    <main>
      <div class="relative pt-16 pb-32 flex content-center items-center justify-center" style="min-height: 75vh;">
        <div class="absolute top-0 w-full h-full bg-center">
          <img src="../assets/img/background.png" class="absolute top-0 w-full h-full bg-center" />
          <span id="blackOverlay" class="w-full h-full absolute bg-black" style="opacity: 0.0"></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  更好  更快  更高效
                </h1>
                <p class="mt-4 text-2xl text-gray-200">
                  新一代 PEM 燃料電池材膜電極 (MEA) </p>
              </div>
            </div>
          </div>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class=" fill-current" points="2560 0 2560 100 0 100" style=" color: #d0dcec"></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20  -mt-8" style="background-color: #0f59aa33 ">
        <div class="container mx-auto px-4">

          <div class="flex flex-wrap items-center ">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                style="color: #0F59AA">
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                新一代膜电极
              </h3>
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                PEM 燃料電池將氫轉化為能量。膜電極組件是燃料電池的核心，使這一轉化成為可能。 </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                透過其專利技術，Epago 開發可按照您燃料電池堆的需求定制的 MEA 和 GDE。 </p>
              <!--<a
                href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                class="font-bold text-gray-800 mt-8"
                >Check Tailwind Starter Kit!</a
              >-->
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg "
                style="background-color: #012463;">
                <img alt="..." src="../assets/img/fc.png" class="w-full align-middle rounded-t-lg" />
                <blockquote class="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                    class="absolute left-0 w-full block" style="height: 95px; top: -94px;">
                    <polygon points="-30,95 583,95 583,65" class="fill-current" style="color: #012463;"></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    超過十年的專業知識
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Epago的團隊由擁有超過十年新能源材料領域經驗的科學家和專業人士組成。
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img alt="..." class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1627052428109-576e839d100a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80" />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full"
                style="background-color: #0F59AA;">
                <i class="fas fa-rocket text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">賦予氫轉型以力量</h3>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                Epago的專利技術非常多樣化，能夠創造適用於不同行業各種應用的 PGM 催化劑。
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="fas fa-fingerprint"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">
                        重型長途運輸用的燃料電池堆
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="fab fa-html5"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">用於叉車、摩托車和輕型摩托車的中小型燃料電池堆</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white mr-3 "
                        style="background-color: #0F59AA;"><i class="far fa-paper-plane"></i></span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">電解裝置用的膜電極組件 (MEA)</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">我們的隊伍</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                我們的團隊由來自世界頂尖大學的國際化學家、量子物理學專家和數據科學家組成
                。
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-1-800x800.jpeg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Andrea Grippi</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    CEO，聯合創始人
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      style="background-color: #012463" type="button" href="https://www.linkedin.com/in/andrea-grippi/"
                      target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-2-800x800.jpeg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Haoyu Zhu</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    CTO、聯合創始人
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      style="background-color: #012463" type="button" href="https://www.linkedin.com/in/haoyu-zhu/"
                      target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/team-4-470x470.png" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Prof. Guanhua Chen</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    董事兼顧問
                  </p>
                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      style="background-color: #012463" type="button"
                      href="https://www.linkedin.com/in/chen-guanhua-569a4a6/" target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-10 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">我們的伙伴</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                我們得到知名學術機構的支持，並與相關私營企業建立關係
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/hku.jpg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">香港大學</h5>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/preinvel.jpg" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Preinvel SRL</h5>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img alt="..." src="../assets/img/hkqai.png" class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;" />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">香港量子人工智能實驗室</h5>

                  <div class="mt-6">
                    <a class="text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      style="background-color: #012463" type="button" href="https://www.linkedin.com/company/hkqai/"
                      target="_blank">
                      <i class="fab fa-linkedin" style="padding-top: 10px;"></i></a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section class="pb-20 relative block " style="background-color: #012463">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="fill-current" style="color: #012463" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">規模化</h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-400">
                我們的技術設計可成規模發展
                它適應快速發展的氫能產業的需求。
                由於我們的專業知識和獨特技術，它不僅能節省時間和經濟成本，還能保證高性能。
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                合適的大小
              </h6>
              <p class="mt-2 mb-4 text-gray-400">
                我們準確的金屬誘導技術使我們能夠製造具有良好耐久性和稳定功率輸出的MEA。 </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                正確的類型
              </h5>
              <p class="mt-2 mb-4 text-gray-400">
                我們的技術適用於大多數貴金屬，使我們能夠製造適用於不同應用的MEA。 </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">正確的時機</h5>
              <p class="mt-2 mb-4 text-gray-400">
                Epago的技術能夠快速製造MEA，使我們即使在定制項目中也能在短時間內交付。</p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative block py-24 lg:pt-0 " style="background-color: #012463">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg"
                style="background-color: #d0dcec">
                <div class="flex-auto p-5 lg:p-10">
                  <form action="https://formcarry.com/s/rKdDiZ4434k" method="POST" accept-charset="UTF-8">
                    <h4 class="text-2xl font-semibold">Want to talk with us?</h4>
                    <p class="leading-relaxed mt-1 mb-4 text-gray-00">
                      Complete this form and we will get back to you as soon as possible.
                    </p>
                    <div class="relative w-full mb-3 mt-8">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="full-name">全名</label><input
                        type="text" name="fullName"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Full Name" style="transition: all 0.15s ease 0s;" />
                    </div>
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="email">電子郵件</label><input
                        type="email" name="email"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Email" style="transition: all 0.15s ease 0s;" />
                    </div>
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="message">信息</label><textarea rows="4" cols="80" name="message"
                        class="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."></textarea>
                    </div>
                    <div class="text-center mt-6">
                      <button
                        class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="submit" style="transition: all 0.15s ease 0s;">
                        發信息
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
export default {
  name: "landing-page-zh-hk",
  components: {
    NavbarComponent,
    FooterComponent
  }
}
</script>
